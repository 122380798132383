import React from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'

import Home from './Screens/Main/Home/home';
import Main from './Screens/Main/Main/main';
import About from './Screens/Main/About/about';
import Message from './Screens/Main/Message/message';
import Service from './Screens/Main/Service/service';
import Products from './Screens/Main/Products/products';
import Clients from './Screens/Main/Clients/clients';

function App() {  

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path="home" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="get-in-touch" element={<Message />} />
          <Route path="service/:name" element={<Service />} />
          <Route path="products/:name" element={<Products />} />
          {/* <Route path="clients" element={<Clients />} /> */}
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
