import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import './service.css';

import sData from './service.json'

import sOne from '../../../Images/Sammy/one.png'
import sTwo from '../../../Images/Sammy/two.png'
import sThree from '../../../Images/Sammy/three.png'
import sFour from '../../../Images/Sammy/four.png'
import sFive from '../../../Images/Sammy/five.png'
import sSix from '../../../Images/Sammy/six.png'
import sSeven from '../../../Images/Sammy/seven.png'
import sEight from '../../../Images/Sammy/eight.png'
import sNine from '../../../Images/Sammy/nine.png'
import sTen from '../../../Images/Sammy/ten.png'

const Service = () => {


  const obj = useLocation();
  const [phone, setPhone] = useState(window.innerWidth < 1025)
  const [objId, setObjId] = useState(obj.state.id)
  const [data, setData] = useState([])
  const [lists, setLists] = useState(false)
  const [subcontent, setSubcontent] = useState(false)
  const [software, setSoftware] = useState(false)

  useEffect(() => {
    setObjId(obj.state.id)
    sData.content.map(i => {
      if(i.id === objId) {
        setData(i)
        Object.keys(i).map(k => {
          if(k === "lists") {setLists(true)}
          else setLists(false)
          if(k === "subcontent") {setSubcontent(true)}
          else setSubcontent(false)
        })
      }
      if(objId === 2) setSoftware(true)
      else setSoftware(false)
    })
    window.scrollTo({top: 0, behavior: "smooth"})
  },[objId,obj.state.id])

  var li = [sOne,sTwo,sThree,sFour,sFive,sSix,sSeven,sEight,sNine,sTen];

  return(
    <div id="ser-container">
      <div id="ser-title">{data.title}</div>
      <div id="ser-img-cont">
        <img src={li[Math.floor(Math.random() * 11)]} id="ser-img"/>
      </div>
      <div id="ser-subtitle">{data.subtitle}</div>
      {
        subcontent && 
        data.subcontent.map(i => {return(
          <div id="ser-subcontent-container">
            <div className="ser-subcontent-title">{i.title}</div>
            <div className="ser-subcontent-subtitle">{i.subtitle}</div>
          </div>
        )})
      }
      {
        lists && 
        data.lists.map(i => {
          if(i.subtitle.length > 8 && !phone) {
            return(
              <div id="ser-lists-container">
                <div className="ser-lists-title">{i.title}</div>
                <div className="ser-lists-subtitle-container ser-list-cont-column">
                {
                  i.subtitle.map(j => <div className="ser-lists-subtitle ser-list-column"><div className="ser-lists-bullet">◾</div>{j}</div>)
                }
                </div>
              </div>
            )
          }else {
            return(
              <div id="ser-lists-container">
                <div className="ser-lists-title">{i.title}</div>
                <div className="ser-lists-subtitle-container">
                {
                  i.subtitle.map(j => <div className="ser-lists-subtitle"><div className="ser-lists-bullet">◾</div>{j}</div>)
                }
                </div>
              </div>
            )
          }
        })
      }
      {
        software && 
        <div id="ser-software-container">
          We provide latest technology like React Native , Flutter to ensure the cross platform design and user experience better

          <div id="ser-tech-list">
            <div className="ser-tech-elements">
              <div className="ser-tech-icon"></div>
              <div className="ser-tech-title">React Native</div>
            </div>
            <div className="ser-tech-elements">
              <div className="ser-tech-icon"></div>
              <div className="ser-tech-title">Flutter</div>
            </div>
            <div className="ser-tech-elements">
              <div className="ser-tech-icon"></div>
              <div className="ser-tech-title">ReactJS</div>
            </div>
            <div className="ser-tech-elements">
              <div className="ser-tech-icon"></div>
              <div className="ser-tech-title">VueJS</div>
            </div>
            <div className="ser-tech-elements">
              <div className="ser-tech-icon"></div>
              <div className="ser-tech-title">Angular</div>
            </div>
            <div className="ser-tech-elements">
              <div className="ser-tech-icon"></div>
              <div className="ser-tech-title">Android</div>
            </div>
            <div className="ser-tech-elements">
              <div className="ser-tech-icon"></div>
              <div className="ser-tech-title">JavaScript</div>
            </div>
            <div className="ser-tech-elements">
              <div className="ser-tech-icon"></div>
              <div className="ser-tech-title">IOS/MacOS</div>
            </div>
            <div className="ser-tech-elements">
              <div className="ser-tech-icon"></div>
              <div className="ser-tech-title">Windows</div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Service;