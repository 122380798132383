import React, {useEffect, useState} from 'react'
import { useNavigate, useLocation} from 'react-router-dom'
import './header.css'

import down from '../../../Images/Icons/down.svg'
import logo from '../../../Images/Logo/logo.png'
import logoB from '../../../Images/Logo/logo-black.png'
import closeB from '../../../Images/Icons/closeBlack.svg'
import closeW from '../../../Images/Icons/closeWhite.svg'
import menuB from '../../../Images/Icons/menuBlack.svg'
import menuW from '../../../Images/Icons/menuWhite.svg'


const Header = () => {

  const nav = useNavigate();
  const loc = useLocation()

  var lastScroll = 0;
  const [scrolled, setScrolled] = useState(true);
  const [oneDrop,setOneDrop] = useState(false);
  const [oneDropA,setOneDropA] = useState(false);
  const [twoDrop,setTwoDrop] = useState(false);
  const [twoDropA,setTwoDropA] = useState(false);

  const [oneDropP,setOneDropP] = useState(false);
  const [oneDropAP,setOneDropAP] = useState(false);
  const [twoDropP,setTwoDropP] = useState(false);
  const [twoDropAP,setTwoDropAP] = useState(false);

  const [headerTwo, setHeaderTwo] = useState(true)

  const [showMenu, setShowMenu] = useState(false);
  const [showMenuAnime, setShowMenuAnime] = useState(false);
  const [backToTop, setBackToTop] = useState(false)

  const [phone, setPhone] = useState(window.innerWidth < 1025);
  const [menuC, setMenuC] = useState(false);

  useEffect(() => {
    nav('home');
    document.addEventListener('scroll',changeHead);
  },[])

  const changeHead = () => {
    let scrollingNow = Math.round(window.scrollY);
    if(scrollingNow > 0) {
      setScrolled(false)
    }else {setScrolled(true)}

    if(scrollingNow > 1300) {
      setBackToTop(true)
    }else {setBackToTop(false)}

    if(scrollingNow > lastScroll && scrollingNow > 300) {setTimeout(() => {setHeaderTwo(false)}, 100)}
    else setHeaderTwo(true)

    lastScroll = scrollingNow;
  }

  const closeMenu = () => {
    setMenuC(false)
    setShowMenuAnime(false)
    setTimeout(() => {setShowMenu(false)}, 500);
  }

  useEffect(() => {

    if(oneDropA) setOneDrop(true);
    else setTimeout(() => {setOneDrop(false)}, 400);

    if(twoDropA) setTwoDrop(true);
    else setTimeout(() => {setTwoDrop(false)}, 400);

    if(oneDropAP) setOneDropP(true);
    else setOneDropP(false);

    if(twoDropAP) setTwoDropP(true);
    else setTwoDropP(false);

  },[oneDropA, twoDropA, oneDropAP, twoDropAP])

  return(<>

    {
      !headerTwo && 
      <div id="no-header-container">
        {backToTop && <img src={down} id="back-to-top" onClick={() => {window.scrollTo({
          top: 0,
          behavior: "smooth"
        })}}/>}
      </div>
    }

    {
      headerTwo && 
      <div id="header-container" style={{
        backgroundColor: menuC ? "#49375e" : scrolled ? "transparent" : "white",
        color: loc.pathname === '/home' ? menuC ? "#49375e" : scrolled ? "white" : "black" : "black",
        boxShadow: scrolled ? "none" : "2px 2px 4px 0 #eee"
      }}>

        {backToTop && <img src={down} id="back-to-top" onClick={() => {window.scrollTo({
          top: 0,
          behavior: "smooth"
        })}}/>}

        {headerTwo && 
          <div id="header-left-container">
            <img id="header-left-logo-icon" src={logo} onClick={() => {nav('home', {replace: true})}}/>
            {/* <div id="header-left-logo-text" style={{color: scrolled ? "#e919fb" : "#1d1c18"}}>roTechXSolutions</div> */}
          </div>
        }
        {
          (!phone && headerTwo) &&
          <div id="header-right-container">
            <div className="header-right-menu" onClick={() => {nav('home', {replace: true})}}>Home</div>
            <div className="header-right-menu" onClick={() => {nav('about')}}>About</div>
            <div className="header-right-menu" onMouseEnter={() => {setOneDropA(true)}} onMouseLeave={() => {setOneDropA(false)}}>
              Service
              {oneDrop && 
                <div className="header-right-drop-box">
                  <div className={oneDropA ? "header-right-dropp header-right-drop-show" : "header-right-dropp header-right-drop-hide"}>
                    <div className="header-right-drop-elements" onClick={() => {nav('../service/erp-implementation',{state: {id: 1}})}}>ERP Implementation</div>
                    <div className="header-right-drop-elements" onClick={() => {nav('../service/software-development',{state: {id: 2}})}}>Software Development</div>
                    <div className="header-right-drop-elements" onClick={() => {nav('../service/project-consultancy',{state: {id: 3}})}}>Project Consultancy</div>
                    <div className="header-right-drop-elements" onClick={() => {nav('../service/data-capturing',{state: {id: 4}})}}>Data Capturing</div>
                    <div className="header-right-drop-elements" onClick={() => {nav('../service/security-implementation',{state: {id: 5}})}}>Security and Identification</div>
                    <div className="header-right-drop-elements" onClick={() => {nav('../service/mobile-application',{state: {id: 6}})}}>Mobile Application</div>
                    <div className="header-right-drop-elements" onClick={() => {nav('../service/fire-and-life-safety-system',{state: {id: 7}})}}>Fire and Life Safety System</div>
                    <div className="header-right-drop-elements" onClick={() => {nav('../service/video-surveillance-system',{state: {id: 8}})}}>Video Surveillance System</div>
                    <div className="header-right-drop-elements" onClick={() => {nav('../service/it-solution-and-products',{state: {id: 9}})}}>IT Solutions and Products</div>
                    <div className="header-right-drop-elements" onClick={() => {nav('../service/queue-management-system',{state: {id: 10}})}}>Queue Management System</div>
                    <div className="header-right-drop-elements" onClick={() => {nav('../service/pos-system-and-cash-drawers',{state: {id: 11}})}}>POS System and Cash Drawers</div>
                    <div className="header-right-drop-elements" onClick={() => {nav('../service/banknote-counters-and-data-collectors',{state: {id: 12}})}}>Banknote Counters and Data Collectors</div>
                  </div>
                </div>  
              }
            </div>
            {/* <div className="header-right-menu">Page</div>  */}
            <div className="header-right-menu" onMouseEnter={() => {setTwoDropA(true)}} onMouseLeave={() => {setTwoDropA(false)}}>
              Products
              {twoDrop && 
                <div className="header-right-drop-box">
                  <div className={twoDropA ? "header-right-dropp header-right-drop-show" : "header-right-dropp header-right-drop-hide"}>
                    <div className="header-right-drop-elements" onClick={() => {nav('../products/erp-system',{state: {id: 11}})}}>ERP System</div>
                    <div className="header-right-drop-elements" onClick={() => {nav('../products/food-system',{state: {id: 12}})}}>Food Soft</div>
                    <div className="header-right-drop-elements" onClick={() => {nav('../products/medi-kit',{state: {id: 13}})}}>Medi Kit</div>
                    <div className="header-right-drop-elements" onClick={() => {nav('../products/cims',{state: {id: 14}})}}>CIMS</div>
                    <div className="header-right-drop-elements" onClick={() => {nav('../products/medi-soft',{state: {id: 15}})}}>Medi Soft</div>
                    <div className="header-right-drop-elements" onClick={() => {nav('../products/ecrm',{state: {id: 16}})}}>eCRM</div>
                    <div className="header-right-drop-elements" onClick={() => {nav('../products/customised-software',{state: {id: 17}})}}>Customised Softwares</div>
                  </div>
                </div>
              }
            </div>
            <div className="header-right-menu" onClick={() => {
              document.querySelector(`#footer-container`).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
              }}>Contact</div>
            <div className="header-right-menu" onClick={() => {nav('get-in-touch')}}>Get in Touch</div>
          </div>
        }
        {
          (phone && headerTwo) &&
          <div id="header-p">
            {menuC ? 
              <img src={menuC ? closeW : closeB} onClick={() => {
                closeMenu()
              }}/> : 
              <img src={menuC ? menuW : menuB} onClick={() => {
                setMenuC(true)
                setShowMenu(true)
                setShowMenuAnime(true)
              }}/>
            }
          </div>        
        }
        {
          ((phone && showMenu) && headerTwo) &&
          <div id="header-two-right-menu-drop" className={showMenuAnime ? "header-drop-menu" : "header-hide-menu"}>
            <div className="phone-menu-top">
              <div className="phone-two-menu-elements" onClick={() => {nav('../home');closeMenu()}}>HOME</div>
              <div className="phone-two-menu-elements" onClick={() => {nav('../about');closeMenu()}}>ABOUT</div>
              <div className="phone-two-menu-elements" onMouseEnter={() => {setOneDropAP(true);setTwoDropAP(false)}} onMouseLeave={() => {setOneDropAP(false)}}>
                SERVICE
                {oneDropP && 
                  <div className="phone-two-menu-drop-box">
                    <div className={oneDropAP ? "phone-two-menu-dropp phone-two-menu-drop-show" : "phone-two-menu-dropp phone-two-menu-drop-hide"}>
                      <div className="phone-two-menu-drop-elements" onClick={() => {nav('../service/erp-implementation',{state: {id: 1}});closeMenu()}}>ERP Implementation</div>
                      <div className="phone-two-menu-drop-elements" onClick={() => {nav('../service/software-development',{state: {id: 2}});closeMenu()}}>Software Development</div>
                      <div className="phone-two-menu-drop-elements" onClick={() => {nav('../service/project-consultancy',{state: {id: 3}});closeMenu()}}>Project Consultancy</div>
                      <div className="phone-two-menu-drop-elements" onClick={() => {nav('../service/data-capturing',{state: {id: 4}});closeMenu()}}>Data Capturing</div>
                      <div className="phone-two-menu-drop-elements" onClick={() => {nav('../service/security-implementation',{state: {id: 5}});closeMenu()}}>Security and Identification</div>
                      <div className="phone-two-menu-drop-elements" onClick={() => {nav('../service/mobile-application',{state: {id: 6}});closeMenu()}}>Mobile Application</div>
                      <div className="phone-two-menu-drop-elements" onClick={() => {nav('../service/fire-and-life-safety-system',{state: {id: 7}});closeMenu()}}>Fire and Life Safety System</div>
                      <div className="phone-two-menu-drop-elements" onClick={() => {nav('../service/video-surveillance-system',{state: {id: 8}});closeMenu()}}>Video Surveillance System</div>
                      <div className="phone-two-menu-drop-elements" onClick={() => {nav('../service/it-solution-and-products',{state: {id: 9}});closeMenu()}}>IT Solutions and Products</div>
                      <div className="phone-two-menu-drop-elements" onClick={() => {nav('../service/queue-management-system',{state: {id: 10}});closeMenu()}}>Queue Management System</div>
                      <div className="phone-two-menu-drop-elements" onClick={() => {nav('../service/pos-system-and-cash-drawers',{state: {id: 11}});closeMenu()}}>POS System and Cash Drawers</div>
                      <div className="phone-two-menu-drop-elements" onClick={() => {nav('../service/banknote-counters-and-data-collectors',{state: {id: 12}});closeMenu()}}>Banknote Counters and Data Collectors</div>
                    </div>
                  </div>  
                }
              </div>
              <div className="phone-two-menu-elements" onMouseEnter={() => {setTwoDropAP(true);setOneDropAP(false)}} onMouseLeave={() => {setTwoDropAP(false)}}>
                PRODUCT
                {twoDropP && 
                  <div className="phone-two-menu-drop-box">
                    <div className={twoDropAP ? "phone-two-menu-dropp phone-two-menu-drop-show" : "phone-two-menu-dropp phone-two-menu-drop-hide"}>
                      <div className="phone-two-menu-drop-elements" onClick={() => {nav('../products/erp-system',{state: {id: 11}});closeMenu()}}>ERP System</div>
                      <div className="phone-two-menu-drop-elements" onClick={() => {nav('../products/food-system',{state: {id: 12}});closeMenu()}}>Food Soft</div>
                      <div className="phone-two-menu-drop-elements" onClick={() => {nav('../products/medi-kit',{state: {id: 13}});closeMenu()}}>Medi Kit</div>
                      <div className="phone-two-menu-drop-elements" onClick={() => {nav('../products/cims',{state: {id: 14}});closeMenu()}}>CIMS</div>
                      <div className="phone-two-menu-drop-elements" onClick={() => {nav('../products/medi-soft',{state: {id: 15}});closeMenu()}}>Medi Soft</div>
                      <div className="phone-two-menu-drop-elements" onClick={() => {nav('../products/ecrm',{state: {id: 16}});closeMenu()}}>eCRM</div>
                      <div className="phone-two-menu-drop-elements" onClick={() => {nav('../products/customised-software',{state: {id: 17}});closeMenu()}}>Customised Softwares</div>
                    </div>
                  </div>
                }
              </div>
              <div className="phone-two-menu-elements" onClick={() => {
                document.querySelector(`#footer-container`).scrollIntoView({behavior: "smooth"});
                closeMenu();
                }}>CONTACT</div>
              <div className="phone-two-menu-elements" onClick={() => {nav('../get-in-touch');closeMenu()}}>GET IN TOUCH</div>
            </div>
          </div>
        }
      </div>
    }
    </>)
}

export default Header;