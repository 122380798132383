import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import './message.css'

import sThree from '../../../Images/Sammy/three.png'

const Message = () => {

  const [message, setMessage] = useState()
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [phoneNo, setPhoneNo] = useState()
  const [extra, setExtra] = useState()
  const [submitting, setSubmitting] = useState(false)


  const nav = useNavigate()

  useEffect(() => {window.scrollTo({top: 0, behavior: "smooth"})},[])

  const sendMsg = async () => {
    setSubmitting(true)
    if(name && name.length > 4) {
      if(email && email.length > 5) {
        if(message && message.length > 10) {
          fetch(`https://secert.protechxsolutions.com/v1/util/send_mail?id=NEW%20QUERY%20FROM%20WEBISTE&from=messages.website@protechxsolutions.com&subject=NEW%20QUERY%20FROM%20PROTECHX%20WEBSITE&to=contact@protechxsolutions.com`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
            body: JSON.stringify({
              message: `
                Client Name: ${name},
                Client Email: ${email},
                Client Phone Number: ${phoneNo},
                Client Message: ${message},
                Client Query: ${extra}
              `
            }),
          })
          .then(res => res.json())
          .then(data => {
            console.log(data.message)
            if(data.message === "success") {
              setName()
              setPhoneNo()
              setMessage()
              setEmail()
              setExtra()
              setSubmitting(false)
              toast.dismiss();
              toast.success("Message sent! We will be in touch with you soon.")
              nav('../home', {replace: true});
            }
            else {
              toast.dismiss();setSubmitting(false)
              toast.error("Failed to send data! please try again later")
            }
          })
        }else {
          toast.dismiss();setSubmitting(false)
          toast.error("Please enter some message")
        }
      }else {
        toast.dismiss();setSubmitting(false)
        toast.error("Please enter your email")
      }
    }else {
      toast.dismiss();setSubmitting(false)
      toast.error("Please enter your name")
    }
  }


  return(
    <div id="message-container">
      <div><Toaster position="top-center" reverseOrder={false}/></div>
      <div id="message-title">Feel free to mail us</div>
      <div id="message-mid-box">
        <div id="message-mid-left">
          <div id="message-mid-text-box">
            <div className="message-inp-box">
              {/* <div className="message-inp-title">Enter your name</div> */}
              <input type="text" className="message-input" id="message-name" required={true} placeholder="Name" value={name} onChange={(e) => {setName(e.target.value)}}/>
            </div>
            <div className="message-inp-box">
              {/* <div className="message-inp-title">Enter mail</div> */}
              <input type="email" className="message-input" id="message-mail" required={true} placeholder="Email"  value={email} onChange={(e) => {setEmail(e.target.value)}}/>
            </div>
            <div className="message-inp-box">
              {/* <div className="message-inp-title">Enter phone number</div> */}
              <input type="number" className="message-input" id="message-phoneno" required={true} placeholder="Phone number"  value={phoneNo} onChange={(e) => {setPhoneNo(e.target.value)}}/>
            </div>
            <textarea 
              name="mail" 
              id="message-mid-text" 
              value={message}  
              placeholder="Type here" 
              required={true}
              onChange={e => {setMessage(e.target.value)}}>
            </textarea>
            <div className="message-inp-box">
              {/* <div className="message-inp-title">Enter phone number</div> */}
              <input list="sols" className="message-input" id="message-phoneno" placeholder="What are you looking for"  value={extra} onChange={(e) => {setExtra(e.target.value)}}/>
              <datalist id="sols">
                <option value="ERP Implementation">ERP Implementation</option>
                <option value="Software Development">Software Development</option>
                <option value="Project Consultancy">Project Consultancy</option>
                <option value="Application and Project Consultancy">Application and Project Consultancy</option>
                <option value="Data Capturing">Data Capturing</option>
                <option value="Security and Identification System">Security and Identification System</option>
                <option value="Mobile Application">Mobile Application</option>
                <option value="Fire and Life Safety System">Fire and Life Safety System</option>
                <option value="Video Surveillance System">Video Surveillance System</option>
                <option value="IT Solutions and Products">IT Solutions and Products</option>
                <option value="Queue Management System">Queue Management System</option>
                <option value="POS System and Cash Drawers">POS System and Cash Drawers</option>
                <option value="Banknote Counters and Data Collectors">Banknote Counters and Data Collectors</option>
                <option value="ERP System">ERP System</option>
                <option value="Food Soft">Food Soft</option>
                <option value="Medi Kit">Medi Kit</option>
                <option value="CIMS">CIMS</option>
                <option value="eCRM">eCRM</option>
                <option value="Medi Soft">Medi Soft</option>
                <option value="Customised Software">Customised Software</option>
              </datalist>
            </div>
          </div>
          {
            submitting ? 
            <div className="message-btn-send">Sending</div> : 
            <div className="message-btn" onClick={() => {sendMsg()}}></div>
          }
        </div>
        <div id="message-mid-right">
          <img src={sThree} id="message-mid-right-img" />  
        </div>
      </div>
    </div>
  )
}

export default Message;